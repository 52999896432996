import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MaxWidth from "../components/Layout/maxWidth"
import Heading from "../components/Layout/heading"
import { styled } from "linaria/react"

import portrait from "../images/Laco_Kacani_talking_about_us.jpg"
import graph from "../images/About-Us-graph0-1.png"
import colors from "../styles/colors"
import { highlightedList } from "../components/Layout/highlightedList"

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 -1em;
  > * {
    flex: 1 0 calc(50% - 2em);
    margin: 0 1em;

    :first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        margin-top: 2em;
      }
    }
  }

  img {
    max-width: 100%;
    min-width: 400px;
  }

  aside span {
    color: ${colors.darkBlue};
    font-weight: 700;
  }
`

export default function AboutUs() {
  return (
    <Layout>
      <SEO title="About us" />
      <Heading>
        <h1>About us</h1>
      </Heading>
      <MaxWidth>
        <Flex>
          <div>
            <ol className={highlightedList}>
              <li>
                <span>01 |</span> 12 years of basic and applied research in
                immunology and oncology
              </li>
              <li>
                <span>02 |</span> 15 years experience in various management
                positions including CEO
              </li>
              <li>
                <span>03 |</span> Third party collaboration and contract
                research in different fields of biotech, pharma, cosmetics,
                dietary supplement and lifestyle industries
              </li>
            </ol>
            <img src={graph} />
          </div>
          <aside>
            <img src={portrait} />
            <span>Laco Kacani, PhD</span>
          </aside>
        </Flex>
      </MaxWidth>
    </Layout>
  )
}
